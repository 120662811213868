/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
import { number } from "./filters.js";
import { date } from "./filters.js";
import { min } from "./filters.js";
import { time } from "./filters.js";

/**
 *  Vue Instance
 */
import Vue from "vue";

window.Vue = Vue;
window.Bus = new Vue();

/**
 *   Container
 */
import App from "./components/App.vue";

/**
 *  Vue Router
 */
import router from "./routes/index.js";

/**
 *  Vuex
 */
import store from "./store/index.js";

/**
 *  Vuetify and Theme Configuration
 */
import vuetify from "./plugins/vuetify.js";

/**
 *  Vee-Validate
 */
import "./vee-validate";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
import * as messages from "vee-validate/dist/locale/es.json";

Object.keys(rules).forEach((rule) => {
    extend(rule, { ...rules[rule], message: messages[rule] });
});

extend("between", {
    message: "Información no valida.",
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);

/**
 *  Abilities
 */
import abilities from "./mixin/abilities";
Vue.mixin(abilities);

/**
 *  Interceptor
 */
import InterceptorsSetup from "./src/helpers/interceptors";

/**
 *  GoogleMaps
 */
//  import * as VueGoogleMaps from 'vue2-google-maps';

//  Vue.use(VueGoogleMaps, {
//     load: {
//     key: '',
//     libraries: 'places',
//     }
// });

/**
 * Firebase configuration.
 */
// import firebase from 'firebase/app';
//  const config = {
//      apiKey: "",
//      authDomain: "",
//      projectId: "",
//      storageBucket: "",
//      messagingSenderId: "",
//      appId: "",
//      measurementId: ""
//    };

//  firebase.initializeApp(config);

Vue.config.productionTip = false;
let app = "";

//  firebase.auth().onAuthStateChanged(() => {
if (!app) {
    InterceptorsSetup();
    app = new Vue({
        router,
        store,
        vuetify,
        ValidationProvider,
        render: (h) => h(App),
    }).$mount("#app");
}

Vue.filter('date', date);
Vue.filter('number', number);
Vue.filter('min', min);
Vue.filter('time', time);
// });
