import api from '../../../api/catalogs/status.js';

const namespaced = true

const state = {
    status_data :[],
   
}
const mutations = {
    SET_STATUS(state, data) {
        state.status_data = data;
    },
 
}
const actions = {
    getStatus(context, all = false) {
        return new Promise((resolve) => { 
             api.getStatus(all).then(function (res) {
                  context.commit('SET_STATUS', res.data.data);
                  resolve(res.data);
             }).catch(function (error) {
                  console.log(error)
             })
        })
   },

}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}