let prefix = '/api/delayEvent';

function getDelayEvent() {
    return window.axios.get(prefix);
}
function getByDates(data) {
    return window.axios.get(prefix+'/ByDates',{params:data});
}
function getByShift(data) {
    return window.axios.get(prefix+'/byShift',{params:data});
}
function store(data) {
    return window.axios.post(prefix, data);
}
function deleteDelayEvent(id) {
    return window.axios.delete(prefix+'/'+id);
}
function update(id,data) {
    return window.axios.patch(prefix+'/'+id,data);
}


export default {
    getDelayEvent,store,deleteDelayEvent,update,getByDates,getByShift
  
}