import api from "../../../api/catalogs/delay_event.js";
import _ from "lodash";

const namespaced = true;

const state = {
    delay_event_data: [],
    delay_event_by_shift_data: [],
    loading: false,
    filter: 0,
    filterFailureType: [
        { id: 6, name: "General" },
        { id: 5, name: "Neumatica" },
        { id: 4, name: "Operativa" },
        { id: 3, name: "Administrativa" },
        { id: 2, name: "Eléctrica" },
        { id: 1, name: "Mecánica" },
    ],
    filterArea: [],
    filterShift: [
        { id: 1, name: "Turno 1" },
        { id: 2, name: "Turno 2" },
        { id: 3, name: "Turno 3" },
        { id: 4, name: "Turno mixto" },
    ],
};
const mutations = {
    SET_DELAY_EVENT(state, data) {
        state.delay_event_data = data;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    ADD_DELAY_EVENT(state, value) {
        state.delay_event_data.push(value);
    },
    DELETE_DELAY_EVENT_COMPONENT(state, value) {
        state.delay_event_data.splice(
            _.findIndex(state.delay_event_data, function (o) {
                return o.id == value;
            }),
            1
        );
    },
    UPDATE_DELAY_EVENT_COMPONENT(state, value) {
        const newArr = _.clone(state.delay_event_data);
        const index = _.findIndex(state.delay_event_data, function (o) {
            return o.id == value.id;
        });
        if (index !== -1) {
            newArr[index] = value;
        }
        state.delay_event_data = newArr;
    },
    SET_FILTER(state, value) {
        state.filter = value;
    },
    SET_FILTER_FAILURE_TYPE(state, value) {
        state.filterFailureType = value;
    },
    SET_FILTER_AREA(state, value) {
        state.filterArea = value;
    },
    SET_FILTER_SHIFT(state, value) {
        state.filterShift = value;
    },
    SET_DELAY_EVENT_BY_SHIFT(state, value) {
        state.delay_event_by_shift_data = value;
    },
};
const actions = {
    getDelayEvent(context) {
        context.commit("SET_LOADING", true);
        return new Promise((resolve) => {
            api.getDelayEvent()
                .then(function (res) {
                    context.commit("SET_DELAY_EVENT", res.data.data);
                    context.commit("SET_LOADING", false);
                    resolve(res.data);
                })
                .catch(function (error) {
                    context.commit("SET_LOADING", false);
                    console.log(error);
                });
        });
    },
    getDelayEventByShift(context,data) {
        context.commit("SET_LOADING", true);
        return new Promise((resolve) => {
            api.getByShift(data)
                .then(function (res) {
                    context.commit("SET_DELAY_EVENT_BY_SHIFT", res.data.data);
                    context.commit("SET_LOADING", false);
                    resolve(res.data);
                })
                .catch(function (error) {
                    context.commit("SET_LOADING", false);
                    console.log(error);
                });
        });
    },
    getDelayEventByDate(context, data) {
        context.commit("SET_LOADING", true);
        return new Promise((resolve) => {
            api.getByDates(data)
                .then(function (res) {
                    context.commit("SET_DELAY_EVENT", res.data.data);
                    context.commit("SET_LOADING", false);
                    resolve(res.data);
                })
                .catch(function (error) {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "error",
                            visibility: true,
                            timeout: 5000,
                            text: error,
                        },
                        { root: true }
                    );
                    context.commit("SET_DELAY_EVENT", []);
                    context.commit("SET_LOADING", false);
                    console.log(error);
                });
        });
    },
    store(context, data) {
        return new Promise((resolve) => {
            api.store(data)
                .then(function (res) {
                    context.commit("ADD_DELAY_EVENT", res.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    update(context, data) {
        return new Promise((resolve) => {
            api.update(data.id, data.data)
                .then(function (res) {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    context.commit(
                        "UPDATE_DELAY_EVENT_COMPONENT",
                        res.data.data
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    delete(context, id) {
        return new Promise((resolve) => {
            api.deleteDelayEvent(id)
                .then(function (res) {
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    context.commit("DELETE_DELAY_EVENT_COMPONENT", id);
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    setFilter(context, value) {
        context.commit("SET_FILTER", value);
    },
    setFilterFailureType(context, value) {
        context.commit("SET_FILTER_FAILURE_TYPE", value);
    },
    setFilterArea(context, value) {
        context.commit("SET_FILTER_AREA", value);
    },
    setFilterShift(context, value) {
        context.commit("SET_FILTER_SHIFT", value);
    },
};

const getters = {
    getDelayEventData(state) {
        const typeIds = _.map(state.filterFailureType, "id");
        const filteredData = _.filter(
            state.delay_event_data,
            (item) =>
                _.get(item, "equipmentFailure.type.id") &&
                _.includes(typeIds, _.get(item, "equipmentFailure.type.id"))
        );
        const filteredDataParticular = _.filter(
            state.delay_event_data,
            (item) =>
                _.get(item, "equipmentFailure.failure_type_name.id") &&
                _.includes(
                    typeIds,
                    _.get(item, "equipmentFailure.failure_type_name.id")
                )
        );
        const filterDataResume = _.concat(filteredData, filteredDataParticular);
        const filterData = _.filter(filterDataResume, (delay_event) => {
            return _.some(delay_event.shifts, (shift) => {
                return _.find(state.filterShift, { name: shift.name });
            });
        });

        return _.orderBy(filterData, ["id"], ["desc"]);
    },
    getDelayEventDataCount(state) {
        return state.delay_event_data.length;
    },
    getDelayEventDataByShift(state) {
        const typeIds = _.map(state.filterFailureType, "id");
        const filteredData = _.filter(
            state.delay_event_by_shift_data,
            (item) =>
                _.get(item, "equipmentFailure.type.id") &&
                _.includes(typeIds, _.get(item, "equipmentFailure.type.id"))
        );
        const filteredDataParticular = _.filter(
            state.delay_event_by_shift_data,
            (item) =>
                _.get(item, "equipmentFailure.failure_type_name.id") &&
                _.includes(
                    typeIds,
                    _.get(item, "equipmentFailure.failure_type_name.id")
                )
        );
        const filterDataResume = _.concat(filteredData, filteredDataParticular);

        const filterData = _.filter(filterDataResume, (delay_event) => {
            return _.find(state.filterShift, { name: delay_event.shift.name });
        });

        return _.orderBy(filterData, ["id"], ["desc"]);
    },
    getDelayEventDataByShiftCount(state) {
        return state.delay_event_by_shift_data.length;
    },
    getDelayEventDataOpen(state) {
        return _.filter(state.delay_event_data, function (o) {
            return o.status_id.id == 1;
        });
    },
    getDelayEventDataClosed(state) {
        return _.filter(state.delay_event_data, function (o) {
            return o.status_id.id == 2;
        });
    },
    getDelayEventExport(state, getter) {
        let transformData = _.map(getter.getDelayEventData, item => [
            item.id,
            item.equipment.sub_area.area,
            item.sub_area.name,
            item.equipment.code,
            item.equipment.name,
            item.equipmentComponent.description,
            item.equipmentFailure.type.name,
            item.equipmentFailure.description,
            item.start_event_date,
            item.end_event_date,
            _.join(_.map( item.shifts, 'name'), ', '),
            item.time_stop,

          
        ]);
        return transformData;
    },
    getDelayEventByShiftExport(state, getter) {
        let transformData = _.map(getter.getDelayEventDataByShift, item => [
            item.id,
            item.equipment.sub_area.area,
            item.sub_area.name,
            item.equipment.code,
            item.equipment.name,
            item.equipmentComponent.description,
            item.equipmentFailure.type.name,
            item.equipmentFailure.description,
            item.start_event_date,
            item.end_event_date,
            item.shift.name,
            item.time_stop,

          
        ]);
        return transformData;
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};
