import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routerOptions = [
    {
        path: "/",
        name: "public",
        visible: false,

    },
    {
        path: "/login",
        name: "login",
        visible: false,
    },
    {
        path: "/register",
        name: "register",
        visible: false,
    },
    {
        path: '/home',
        name: 'home',
        visible: false,
        meta: {
          title: 'Inicio'
        }
      },
    {
        path: "/users",
        name: "users",
        meta: {
            title: "Usuarios / Gestión de Usuarios",
            permissions: [
                "get-users",
                "store-users",
                "update-users",
                "delete-users",
                "get-abilities",
                "store-abilities",
                "update-abilities",
                "delete-abilities",
                "get-roles",
                "store-roles",
                "update-abilities",
                "delete-abilities",
            ],
        },
        visible: true,
    },
    {
        path: '/catalogs',
        name: 'catalogs',
        meta: {
            title: "Catálogos",
            permissions: [
                'get_plants_aras',
                'store_plants_areas',
                'update_plants_aras',
                'desactivate_plants_aras',
                'get_plants_aras_sub',
                'store_plants_areas_sub',
                'update_plants_aras_sub',
                'desactivate_plants_aras_sub'
            ],
        },
        visible: false,
    },
    {
        path: '/catalogs/plants',
        name: 'catalogs/plants',
        meta: {
            title: "Catálogos / Plantas",
            permissions: [
                'get_plants_aras',
                'store_plants_areas',
                'update_plants_aras',
                'desactivate_plants_aras',
            ],
        },
        visible: true,
    },
    {
        path: '/catalogs/plants_areas',
        name: 'catalogs/plants_areas',
        meta: {
            title: "Catálogos / Áreas de producción",
            permissions: [
                'get_plants_aras_sub',
                'store_plants_areas_sub',
                'update_plants_aras_sub',
                'desactivate_plants_aras_sub'
            ],
        },
        visible: true,
    },
    {
        path: '/equipment',
        name: 'equipment',
        meta: {
            title: "Equipos/ Gestión de equipos",
            permissions: [
                'get_equipment',
                'store_equipment',
                'update_equipment',
                'desactivate_equipment',
            ],
        },
        visible: true,
    },
    {
        path: '/equipment/equipment_general',
        name: 'equipment/equipment_general',
        meta: {
            title: "Equipos / Equipos genéricos",
            permissions: [
                'get_equipment_general',
                'store_equipment_general',
                'update_equipment_general',
                'get_equipment_general_component',
                'store_equipment_general_component',
                'update_equipment_general_component',
                'desactivate_equipment_general_component',
                'get_equipment_general_failure',
                'store_equipment_general_failure',
                'update_equipment_general_failure',
                'desactivate_equipment_general_failure',
            ],
        },
        visible: true,
    },
    {
        path: '/delayEvent',
        name: 'delayEvent',
        meta: {
            title: "Demoras/ Gestión eventos de demora",
            permissions: [
                'get_delay_event',
                'store_delay_event',
                'update_delay_event',
                'delate_delay_event',
            ],
        },
        visible: true,
    },
    {
        path: "/logout",
        name: "logout",
        visible: false,
    },
];

// Rutas
const routes = routerOptions.map((r) => {
    return {
        ...r,
        component: () => import(`../components/${r.name}/index-component.vue`),
    };
});

const router = new Router({
    //mode: "history",
    base: __dirname,
    routes,
});

export default router;
