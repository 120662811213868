let prefix = '/api/usersPlants';

function assignToUserPlant(data) {
    return window.axios.post(prefix,data);
}
function unassignFromUserPlant(data) {
    return window.axios.delete(prefix,{params:data});
}


export default {
    assignToUserPlant,unassignFromUserPlant
    
}