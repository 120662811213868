<template>
    
        <v-avatar>
            <img src="./../../../../public/images/apps/hifos-logo.png" alt="John">
        </v-avatar>
  
</template>

<script>


export default {
    props: [''],
    data() {

        return {
        }
    },
    components: {},
    methods: {},
    beforeMount() { },
    mounted() { },
    computed: {},
    watch: {}
}
</script>

<style></style>