import api from "../../../api/catalogs/equipment_general.js";
import apiEGC from './../../../api/catalogs/equipment_general_component.js';
import apiEGF from './../../../api/catalogs/equipment_general_failure.js';
import _ from 'lodash';

const namespaced = true;

const state = {
    equipment_general_data: [],
    equipment_general_select: null,
    loading: false,
    message : null,
};
const mutations = {
    SET_EQUIPMENT_GENERAL(state, data) {
        state.equipment_general_data = data;
    },
    SET_EQUIPMENT_GENERAL_SELECT(state, value) {
        state.equipment_general_select = value;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    ADD_EQUIPMENT_GENERAL(state,value){
        state.equipment_general_data.push(value)
    },
    //Componentes generales
    ADD_EQUIPMENT_GENERAL_COMPONENT(state,value){
        state.equipment_general_select.components.push(value)
    },
    DELETE_EQUIPMENT_GENERAL_COMPONENT(state, value) {
        state.equipment_general_select.components.splice(_.findIndex(state.equipment_general_select.components, function (o) { return o.id == value }), 1)
    },
    UPDATE_EQUIPMENT_GENERAL_COMPONENT(state, value) {
        const newArr = _.clone( state.equipment_general_select.components);
        const index = _.findIndex( state.equipment_general_select.components, function (o) { return o.id == value.id });
        if (index !== -1) {
            newArr[index] = value;
          }
        state.equipment_general_select.components = newArr
    },
    SET_MESSAGE_WARNING(state,value){
        state.message = value;
    },
    //Fallas generales
    ADD_EQUIPMENT_GENERAL_FAILURE(state,value){
        state.equipment_general_select.failures.push(value)
    },
    DELETE_EQUIPMENT_GENERAL_FAILURE(state, value) {
        state.equipment_general_select.failures.splice(_.findIndex(state.equipment_general_select.failures, function (o) { return o.id == value }), 1)
    },
    UPDATE_EQUIPMENT_GENERAL_FAILURE(state, value) {
        const newArr = _.clone( state.equipment_general_select.failures);
        const index = _.findIndex( state.equipment_general_select.failures, function (o) { return o.id == value.id });
        if (index !== -1) {
            newArr[index] = value;
          }
        state.equipment_general_select.failures = newArr
    },

};
const actions = {
    getEquipmentGeneral(context, all = false) {
        context.commit("SET_LOADING", true);
        return new Promise((resolve) => {
            api.getEquipmentGeneral(all)
                .then(function (res) {
                    context.commit("SET_EQUIPMENT_GENERAL", res.data.data);
                    resolve(res.data);
                    context.commit("SET_LOADING", false);
                })
                .catch(function (error) {
                    console.log(error);
                    context.commit("SET_LOADING", false);
                });
        });
    },
    setEquipmentGeneral(context, value) {
        context.commit("SET_EQUIPMENT_GENERAL_SELECT", value);
    },
    store(context,data){
        return new Promise((resolve) => {
            api.store(data)
                .then(function (res) {
                    context.commit("ADD_EQUIPMENT_GENERAL", res.data.data);
                    resolve(res.data);
                    context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        });    
    },
    //Componentes equipos generales
    addComponent(context,data){
        return new Promise((resolve) => {
            apiEGC.store(data)
                .then(function (res) {
                    context.commit("ADD_EQUIPMENT_GENERAL_COMPONENT", res.data.data);
                    resolve(res.data);
                    context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        });    
    },
    desactiveComponent(context,id){
        return new Promise((resolve) => {
            apiEGC.desactive(id)
                .then(function (res) {
                    context.commit("DELETE_EQUIPMENT_GENERAL_COMPONENT",id);
                    resolve(res.data);
                    context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        });    
    },
    updateComponent(context,data){
        return new Promise((resolve) => {
            apiEGC.update(data.id,data.data)
                .then(function (res) {
                    context.commit("UPDATE_EQUIPMENT_GENERAL_COMPONENT",res.data.data);
                    resolve(res.data);
                    context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        });    
    },
    storeWarning(context,data){
        return new Promise((resolve) => {
            apiEGC.warningStore(data)
                .then(function (res) {
                    console.log(res.data.data)
                    context.commit("SET_MESSAGE_WARNING",res.data.data);
                    resolve(res.data);
                    
                })
                .catch(function (error) {
                    context.commit("SET_MESSAGE_WARNING",error.messages);
                  
                });
        });    
    },
    //Fallas equipos generales
    addFailure(context,data){
        return new Promise((resolve) => {
            apiEGF.store(data)
                .then(function (res) {
                    context.commit("ADD_EQUIPMENT_GENERAL_FAILURE", res.data.data);
                    resolve(res.data);
                    context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        });    
    },
    updateFailure(context,data){
        return new Promise((resolve) => {
            apiEGF.update(data.id,data.data)
                .then(function (res) {
                    context.commit("UPDATE_EQUIPMENT_GENERAL_FAILURE",res.data.data);
                    resolve(res.data);
                    context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        });    
    },
    desactiveFailure(context,id){
        return new Promise((resolve) => {
            apiEGF.desactive(id)
                .then(function (res) {
                    context.commit("DELETE_EQUIPMENT_GENERAL_FAILURE",id);
                    resolve(res.data);
                    context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
                    
                })
                .catch(function (error) {
                    console.log(error);
                });
        });    
    },


};

const getters = {
    getEquipmentGeneralParts(state){
        return (state.equipment_general_select!=null)?_.orderBy(state.equipment_general_select.components, ['id'], ['desc']):[];
    },
    getEquipmentGeneralFailures(state){
        return (state.equipment_general_select!=null)?_.orderBy(state.equipment_general_select.failures, ['id'], ['desc']):[];
    },
    getEquipmentGeneralCount(state){
        return state.equipment_general_data.length;
    },
    getEquipmentGeneral(state){
        return _.orderBy(state.equipment_general_data, ['id'], ['desc']);
    }

};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};
