let prefix = '/api/component';

function store(data) {
    return window.axios.post(prefix,data);
}
function desactive(id) {
    return window.axios.delete(prefix+'/'+id);
}
function update(id,data) {
    return window.axios.patch(prefix+'/'+id, data);
}
function warningStore(data) {
    return window.axios.post(prefix+'/warningStore', data);
}

export default {
    store,desactive,update,warningStore
    
}