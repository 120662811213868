import _ from 'lodash'

export function number(value) {
    return _.floor(value, 2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
}

export function date(value) {
    return (value.length <= 10) ? value : value.substr(0, value.length - 3);
}

export function min(value){
    return Math.floor(value / 60);
}
export function time(value){
        return value.slice(0, 5); 
}

