
import api from '../../../api/catalogs/plants_areas_shift.js';
import _ from 'lodash';

const namespaced = true

const state = {
    plants_areas_shifts_data :[],
 
}
const mutations = {
     //Areas
     SET_PLANTS_AREAS_SHIFTS(state, data) {
        state.plants_areas_shifts_data = data;
     },
    
}
const actions = {
    getPlantsAreasShifts(context) {
        return new Promise((resolve) => { 
             api.getProductionAreaShiftType().then(function (res) {
                  context.commit('SET_PLANTS_AREAS_SHIFTS', res.data.data);
                  resolve(res.data);
             }).catch(function (error) {
                  console.log(error)
             })
        })
   },
   

 
}

const getters = {
     getAreasShiftsPlants(state) {
          return _.orderBy(state.plants_areas_shifts_data, ['id'], ['desc'])
     },
      
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}