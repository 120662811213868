let prefix = '/api/productionArea';

function getProductionAreas() {
    return window.axios.get(prefix);
}
function store(data) {
    return window.axios.post(prefix,data);
}
function desactivate(id) {
    return window.axios.delete(prefix+'/'+id);
}
function update(id,data) {
    return window.axios.patch(prefix+'/'+id, data);
}

export default {
    getProductionAreas,store,desactivate,update
    
}