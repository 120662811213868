<template>
    <v-container class="text-center">
        <v-menu rounded="b-xl" transition="scale-transition">
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="float-right" icon v-bind="attrs" v-on="on">
                    <v-icon dark>
                        mdi-page-next-outline
                    </v-icon>
                </v-btn>
            </template>
            <v-list flat>
                <v-list-item v-for="(item, index) in quickaccess" :key="index" link>
                    <v-list-item-content>
                        <v-list-item-title @click="goTo(item)">{{ item.label }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-menu>
    </v-container>
</template>

<script>
import { mapState, mapActions  } from 'vuex'

export default {
    props: [''],
    data() {
        const router = router
        return {
            items: [],
        }
    },
    components: {},
    methods: {
        ...mapActions('activities', ['filterQuickAccess']),
        goTo(data) {
            const routeData = this.$router.resolve({ name: data.name });
            window.open(routeData.href, '_blank');
        }
    },
    beforeMount() { },
    mounted() {
        this.filterQuickAccess();
    },
    computed: {
        ...mapState('activities', ['quickaccess']),
        
    },
    watch: {}
}
</script>

<style></style>