let prefix = '/api/failure';

function store(data) {
    return window.axios.post(prefix,data);
}

function update(id,data) {
    return window.axios.patch(prefix+'/'+id, data);
}

function desactive(id) {
    return window.axios.delete(prefix+'/'+id);
}


export default {
    store,update,desactive
    
}