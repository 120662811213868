import api from "../../../api/catalogs/failure_type.js";
const namespaced = true;

const state = {
    failure_type_data: [],
};
const mutations = {
    SET_FAILURE_TYPE(state, data) {
        state.failure_type_data = data;
    },
};
const actions = {
    getFailureType(context) {
        return new Promise((resolve) => {
            api.getFailureType()
                .then(function (res) {
                    context.commit("SET_FAILURE_TYPE", res.data.data);

                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
};

const getters = {};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};
