let prefix = '/api/equipmentComponent';

function store(data) {
    return window.axios.post(prefix, data);
}
function deleteComponent(id) {
    return window.axios.delete(prefix+'/'+ id);
}
function update(id,data) {
    return window.axios.patch(prefix+'/'+ id,data);
}

export default {
    store,deleteComponent,update
  
}