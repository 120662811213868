const namespaced = true;

const state = {
    day_data: [
        { id: 1, name: "Lunes" },
        { id: 2, name: "Martes" },
        { id: 3, name: "Miercoles" },
        { id: 4, name: "Jueves" },
        { id: 5, name: "Viernes" },
        { id: 6, name: "Sabado" },
        { id: 7, name: "Domingo" },
    ],
};
const mutations = {};
const actions = {};

const getters = {};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};
