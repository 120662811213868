import Vue from 'vue';
import Vuex from 'vuex';

/**
 *  Para mantener el estado de vuex-states de modulos especificos.
 */
import createPersistedState from  'vuex-persistedstate';

Vue.use(Vuex);

import configuration        from  './modules/configuration.js';
import notifications        from  './modules/notifications.js';
import authentication       from  './modules/authentication.js';
import abilities            from  './modules/abilities.js';
import user                 from  './modules/user.js';
import activities           from  './modules/activities.js';
import roles                from  './modules/roles.js';
import date                 from './modules/date.js';
//catalogs
import plants               from './modules/catalogs/plants.js';  
import plants_areas         from './modules/catalogs/plants_areas.js';  
import equipment            from './modules/catalogs/equipment.js'
import delay_event          from './modules/catalogs/delay_event.js'; 
import failure_type         from './modules/catalogs/failure_type.js';    
import equipment_general    from './modules/catalogs/equipment_general.js';
import status               from './modules/catalogs/status.js';  
import plants_areas_shift   from './modules/catalogs/plants_areas_shifts.js';  
import days                 from   './modules/catalogs/days.js'  
//Utils
import export_data          from './modules/Doc/export_data.js'; 

const dataState = createPersistedState({
  paths: ['configuration','activities',]
})

export default new Vuex.Store({
  namespace: true,
  state: {},
  mutations: {},
  actions: {},
  modules: {
    configuration,
    notifications,
    authentication,
    abilities,
    user,
    activities,
    roles,
    date,
    //Catalogs MES
    plants,
    plants_areas,
    equipment,
    delay_event,
    failure_type,
    equipment_general,
    status,
    plants_areas_shift,
    days,
    //Utils
    export_data
  },
  plugins: [dataState]
})