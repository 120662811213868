let prefix = '/api/subProductionArea';

function store(data) {
    return window.axios.post(prefix,data);
}
function desactivate(id) {
    return window.axios.delete(prefix+'/'+id);
}
function update(id,data) {
    return window.axios.patch(prefix+'/'+id , data);
}

export default {
    store,desactivate,update
    
}