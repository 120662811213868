import api from './../../../api/catalogs/plants.js';

const namespaced = true

const state = {
    plants_data :[],
    plants_select : null
}
const mutations = {
    SET_PLANTS(state, data) {
        state.plants_data = data;
    },
    SET_PLANT_SELECT(state, data) {
        state.plants_select = data;
    },
}
const actions = {
    getPlants(context, all = false) {
        return new Promise((resolve) => { 
             api.getPlants(all).then(function (res) {
                  context.commit('SET_PLANTS', res.data.data);
                  resolve(res.data);
             }).catch(function (error) {
                  console.log(error)
             })
        })
   },
   setPlant(context,data){
     context.commit('SET_PLANT_SELECT',data);
   }
}

const getters = {
     getAreasPlants(state) {
          return state.plants_select != null
              ? state.plants_select.areas
              : [];
     },
     // getAreasPlantsSub(state) {
     //      return state.plants_select != null
     //          ? state.plants_select.areas
     //          : [];
     //  },
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}