let prefix = '/api/equipmentCatalog';

function getEquipmentGeneral() {
    return window.axios.get(prefix);
}

function store(data) {
    return window.axios.post(prefix,data);
}

export default {
    getEquipmentGeneral,store
    
}