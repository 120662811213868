import api from '../../../api/catalogs/plants_areas.js';
import apiAreaSub from '../../../api/catalogs/plants_areas_sub.js';
import apiAreaShift from '../../../api/catalogs/plants_areas_shift.js';
import _ from 'lodash';

const namespaced = true

const state = {
    plants_areas_data :[],
    plants_area_select : null,
    plants_area_sub_select : null,
    loading : false
}
const mutations = {
     //Areas
     SET_PLANTS_AREAS(state, data) {
        state.plants_areas_data = data;
     },
     SET_PLANTS_AREA_SELECT(state, value) {
        state.plants_area_select = value;
     },
     SET_PLANTS_AREA_SUB_SELECT(state, value) {
        state.plants_area_sub_select = value;
     },
     ADD_AREA(state, value) {
          state.plants_areas_data.push(value);
     },
     DELETE_AREA(state, value) {
          state.plants_areas_data.splice(_.findIndex(state.plants_areas_data, function (o) { return o.id == value }), 1)
     },
     UPDATE_AREA(state, value) {
          const newArr = _.clone(state.plants_areas_data);
          const index = _.findIndex(state.plants_areas_data, function (o) { return o.id == value.id });
          if (index !== -1) {
              newArr[index] = value;
            }
         state.plants_areas_data = newArr
     },
     SET_LOADING(state, value) {
          state.loading = value;
      },
     //SubAreas
     ADD_SUB_AREA(state, value) {
          state.plants_area_select.subAreas.push(value);
     },
     DELETE_SUB_AREA(state, value) {
          state.plants_area_select.subAreas.splice(_.findIndex(state.plants_area_select.subAreas, function (o) { return o.id == value }), 1)
     },
     UPDATE_SUB_AREA(state, value) {
          const newArr = _.clone(state.plants_area_select.subAreas);
          const index = _.findIndex(state.plants_area_select.subAreas, function (o) { return o.id == value.id });
          if (index !== -1) {
              newArr[index] = value;
            }
         state.plants_area_select.subAreas = newArr
     },
     //Shift
     ADD_SHIFT_AREA(state, value) {
          state.plants_area_select.shifts.push(value);
     },
     DELETE_SHIFT_AREA(state, value) {
          state.plants_area_select.shifts.splice(_.findIndex(state.plants_area_select.shifts, function (o) { return o.id == value }), 1)
     },
}
const actions = {
    getPlantsAreas(context) {
        context.commit('SET_LOADING', true);
        return new Promise((resolve) => { 
             api.getProductionAreas().then(function (res) {
                  context.commit('SET_PLANTS_AREAS', res.data.data);
                  context.commit('SET_LOADING', false);
                  resolve(res.data);
             }).catch(function (error) {
                  console.log(error)
                  context.commit('SET_LOADING', false);
             })
        })
   },
   setPlantAreaSelect(context,data){
     context.commit('SET_PLANTS_AREA_SELECT',data)
   },
   setPlantAreaSubSelect(context,data){
     context.commit('SET_PLANTS_AREA_SUB_SELECT',data)
   },
   store(context,data){
     return new Promise((resolve) => { 
          api.store(data).then(function (res) {
               context.commit('ADD_AREA', res.data.data);
               context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
               resolve(res.data);
          }).catch(function (error) {
               console.log(error)
          })
     })

   },
   desactivate(context,id){
     return new Promise((resolve) => { 
          api.desactivate(id).then(function (res) {
               context.commit('DELETE_AREA', id);
               context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
               resolve(res.data);
          }).catch(function (error) {
               console.log(error)
          })
     })

   },
   update(context,data){
     return new Promise((resolve) => { 
          api.update(data.id,data.data).then(function (res) {
               context.commit('UPDATE_AREA', res.data.data);
               context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
               resolve(res.data);
          }).catch(function (error) {
               console.log(error)
          })
     })

   },
   //SubAreas
   addSubArea(context,data) {
     return new Promise((resolve) => { 
          apiAreaSub.store(data).then(function (res) {
               context.commit('ADD_SUB_AREA', res.data.data);
               context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
               resolve(res.data);
          }).catch(function (error) {
               console.log(error)
          })
     })
   },
   deleteSubArea(context,id) {
     return new Promise((resolve) => { 
          apiAreaSub.desactivate(id).then(function (res) {
               context.commit('DELETE_SUB_AREA', res.data.data);
               context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
               resolve(res.data);
          }).catch(function (error) {
               console.log(error)
          })
     })
   },
   updateSubArea(context,data) {
     return new Promise((resolve) => { 
          apiAreaSub.update(data.id, data.data).then(function (res) {
               context.commit('UPDATE_SUB_AREA', res.data.data);
               context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
               resolve(res.data);
          }).catch(function (error) {
               console.log(error)
          })
     })
   },
   //Shifts
   addShiftArea(context,data) {
     return new Promise((resolve) => { 
          apiAreaShift.store(data).then(function (res) {
               context.commit('ADD_SHIFT_AREA', res.data.data);
               context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
               resolve(res.data);
          }).catch(function (error) {
               console.log(error)
          })
     })
   },
   desactivateShiftArea(context,id){
     return new Promise((resolve) => { 
          apiAreaShift.desactive(id).then(function (res) {
               context.commit('DELETE_SHIFT_AREA', id);
               context.dispatch("notifications/add",{color: "success",visibility: true,timeout: 5000,text: res.data.message,},{ root: true });
               resolve(res.data);
          }).catch(function (error) {
               console.log(error)
          })
     })

   },

 
}

const getters = {
     getAreasPlants(state,getter,rootState) {
          return ( rootState.plants.plants_select!=null)? _.orderBy(_.filter(state.plants_areas_data, function(o) { return o.plant_id == rootState.plants.plants_select.plant_id}), ['id'], ['desc']):[]; 
     },
     getAreasPlantsSub(state){
          return (state.plants_area_select!=null)? _.orderBy(state.plants_area_select.subAreas, ['id'], ['desc']):[];
     },
     getAreasPlantsShifts(state){
          return (state.plants_area_select!=null)? _.orderBy(state.plants_area_select.shifts, ['day_id'], ['asc']):[];
     },
     getAreasPlantsSubTotal(state) {
          return (state.plants_area_select!=null)?state.plants_area_select.subAreas.length:0;
      },
     getAreasPlantsShiftsTotal(state) {
          return (state.plants_area_select!=null)?state.plants_area_select.shifts.length:0;
      },
      getAreaSelectName(state) {
          return state.plants_area_select != null
              ? state.plants_area_select.name
              : null;
      },
      getPlants(state){
         return _.orderBy(state.plants_areas_data, ['id'], ['desc']);
      },
      getPlantAreasExport(state) {
          let transformData = _.map(state.plants_areas_data, item => [
              item.name,
              _.join(_.map( item.subAreas, 'name'), ', '),
              _.join(_.map(item.shifts, shift => `${shift.name} ${shift.day} ${shift.start} a ${shift.end}`), ', ')
              ,
  
            
          ]);
          return transformData;
      },
      
}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}