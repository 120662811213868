let prefix = '/api/productionAreaShift';

function getProductionAreaShiftType() {
    return window.axios.get(prefix+'/typeShift');
}
function store(data) {
    return window.axios.post(prefix,data);
}
function desactive(id) {
    return window.axios.delete(prefix+'/'+id);
}


export default {
    getProductionAreaShiftType,store,desactive
    
}