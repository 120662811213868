let prefix = '/api/equipmentFailures';

function store(data) {
    return window.axios.post(prefix, data);
}
function update(id,data) {
    return window.axios.patch(prefix+'/'+id, data);
}

export default {
    store,update
  
}