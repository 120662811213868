import api from './../../../api/Doc/export_data.js'
import { downloadFile } from '../../../src/helpers/util.js'

const namespaced = true

const state = {
    download: false,
}
const mutations = {
    SET_LOADING(state, value) {
        state.download = value;
    },
}
const actions = {
    ExportDataByExcel(context, data) {
        return new Promise((resolve) => {
            context.commit('SET_LOADING', true);
            api.exportDataExcel(data.data).then(function (res) {
                downloadFile(res.data, data.name);
                context.commit('SET_LOADING', false);
                resolve(res.data);
            }).catch((e) => {
                context.commit('SET_LOADING', false);
                context.dispatch('notifications/add', { 'color': 'error', 'visibility': true, 'timeout': 5000, 'text': e.messages }, { root: true });
            })
        })

    },
}

const getters = {}

export default {
     namespaced,
     state,
     mutations,
     actions,
     getters,
}