let prefix = '/api/equipment';

function get() {
    return window.axios.get(prefix);
}
function store(data) {
    return window.axios.post(prefix, data);
}
function deleteEquipment(id) {
    return window.axios.delete(prefix+'/'+id);
}
function update(id,data) {
    return window.axios.patch(prefix+'/'+id , data);
}

export default {
    get,store,deleteEquipment,update
  
}