import api from "../../../api/catalogs/equipment.js";
import apiEquipmentComponent from "./../../../api/catalogs/equipment_component.js";
import apiEquipmentFailure from "./../../../api/catalogs/equipment_failure.js";
import _ from "lodash";

const namespaced = true;

const state = {
    equipments: [],
    equipmentSelect: null,
    loading: false,
};
const mutations = {
    SET_EQUIPMENTS(state, data) {
        state.equipments = data;
    },
    SET_EQUIPMENT(state, value) {
        state.equipmentSelect = value;
    },
    SET_LOADING(state, value) {
        state.loading = value;
    },
    //Componentes particulares
    ADD_EQUIPMENT_COMPONENT(state, value) {
        state.equipmentSelect.componentes.push(value);
    },
    DELETE_EQUIPMENT_COMPONENT(state, value) {
        state.equipmentSelect.componentes.splice(
            _.findIndex(state.equipmentSelect.componentes, function (o) {
                return o.id == value;
            }),
            1
        );
    },
    UPDATE_EQUIPMENT_COMPONENT(state, value) {
        const newArr = _.clone(state.equipmentSelect.componentes);
        const index = _.findIndex(
            state.equipmentSelect.componentes,
            function (o) {
                return o.id == value.id;
            }
        );
        if (index !== -1) {
            newArr[index] = value;
        }
        state.equipmentSelect.componentes = newArr;
    },
    //Fallas particulares
    ADD_EQUIPMENT_FAILURE(state, value) {
        state.equipmentSelect.failures.push(value);
    },
    UPDATE_EQUIPMENT_FAILURE(state, value) {
        const newArr = _.clone(state.equipmentSelect.failures);
        const index = _.findIndex(
            state.equipmentSelect.failures,
            function (o) {
                return o.id == value.id;
            }
        );
        if (index !== -1) {
            newArr[index] = value;
        }
        state.equipmentSelect.failures = newArr;
    },
    //Generales
    ADD_EQUIPMENT(state, value) {
        state.equipments.push(value);
    },
    DELETE_EQUIPMENT(state, value) {
        state.equipments.splice(
            _.findIndex(state.equipments, function (o) {
                return o.id == value;
            }),
            1
        );
    },
    UPDATE_EQUIPMENT(state, value) {
        const newArr = _.clone(state.equipments);
        const index = _.findIndex(state.equipments, function (o) {
            return o.id == value.id;
        });
        if (index !== -1) {
            newArr[index] = value;
        }
        state.equipments = newArr;
    },
};
const actions = {
    setEquipment(context, value) {
        context.commit("SET_EQUIPMENT", value);
    },
    getEquipments(context) {
        return new Promise((resolve) => {
            context.commit("SET_LOADING", true);
            api.get()
                .then(function (res) {
                    context.commit("SET_EQUIPMENTS", res.data.data);
                    context.commit("SET_LOADING", false);
                    resolve(res.data);
                })
                .catch(function (error) {
                    context.commit("SET_LOADING", false);
                    console.log(error);
                });
        });
    },
    store(context, data) {
        return new Promise((resolve) => {
            api.store(data)
                .then(function (res) {
                    context.commit("ADD_EQUIPMENT", res.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    update(context, data) {
        return new Promise((resolve) => {
            api.update(data.id, data.data)
                .then(function (res) {
                    context.commit("UPDATE_EQUIPMENT", res.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    delete(context, id) {
        return new Promise((resolve) => {
            api.deleteEquipment(id)
                .then(function (res) {
                    context.commit("DELETE_EQUIPMENT", id);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    //Componentes generales
    addComponent(context, data) {
        return new Promise((resolve) => {
            apiEquipmentComponent
                .store(data)
                .then(function (res) {
                    context.commit("ADD_EQUIPMENT_COMPONENT", res.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    deleteComponent(context, id) {
        return new Promise((resolve) => {
            apiEquipmentComponent
                .deleteComponent(id)
                .then(function (res) {
                    context.commit("DELETE_EQUIPMENT_COMPONENT", res.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    updateComponent(context, data) {
        return new Promise((resolve) => {
            apiEquipmentComponent
                .update(data.id, data.data)
                .then(function (res) {
                    context.commit("UPDATE_EQUIPMENT_COMPONENT", res.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    //Fallas generales
    addFailure(context, data) {
        return new Promise((resolve) => {
            apiEquipmentFailure
                .store(data)
                .then(function (res) {
                    context.commit("ADD_EQUIPMENT_FAILURE", res.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
    updateFailure(context, data) {
        return new Promise((resolve) => {
            apiEquipmentFailure
                .update(data.id , data.data)
                .then(function (res) {
                    // context.commit("ADD_EQUIPMENT_FAILURE", res.data.data);
                    context.commit("UPDATE_EQUIPMENT_FAILURE", res.data.data);
                    context.dispatch(
                        "notifications/add",
                        {
                            color: "success",
                            visibility: true,
                            timeout: 5000,
                            text: res.data.message,
                        },
                        { root: true }
                    );
                    resolve(res.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        });
    },
};

const getters = {
    getEquipmentAssign(state) {
        return _.orderBy(state.equipments, ["id"], ["desc"]);
    },
    getEquipmentComponentList(state) {
        return state.equipmentSelect != null
            ? _.orderBy(state.equipmentSelect.componentes, ["id"], ["desc"])
            : [];
    },
    getEquipmentFailureList(state) {
        return state.equipmentSelect != null
            ? _.orderBy(state.equipmentSelect.failures, ["id"], ["desc"])
            : [];
    },
    getEquipmentComponentListTotal(state) {
        return state.equipmentSelect != null
            ? state.equipmentSelect.componentes.length
            : 0;
    },
    getEquipmentFailureListTotal(state) {
        return state.equipmentSelect != null
            ? state.equipmentSelect.failures.length
            : 0;
    },
    getEquipmentSelectName(state) {
        return state.equipmentSelect != null
            ? state.equipmentSelect.name
            : null;
    },
    getEquipmentsStore(state, getters, rootState) {
        return rootState.plants_areas.plants_area_sub_select != null
            ? _.filter(state.equipments, function (o) {
                  return (
                      o.sub_production_area_id ==
                      rootState.plants_areas.plants_area_sub_select.id
                  );
              })
            : [];
    },
    getEquipmentsByPlant(state, getters, rootState) {
        const plantIds = rootState.configuration.userAuth.plants.map(
            (o) => o.plant_id

        );
        return state.equipments.filter((equipment) =>
            plantIds.includes(equipment.plant.id)
        );
    },
    getEquipmentCount(state) {
        return state.equipments.length;
    },

    getEquipmentExport(state) {
        let transformData = _.map(state.equipments, item => [
            item.code,
            item.name,
            item.sub_area.area,
            item.sub_area.name,
            _.join(_.map( item.componentes, 'name'), ', '),
            _.join(_.map( item.failures, 'description'), ', '),
            

          
        ]);
        return transformData;
    },
};

export default {
    namespaced,
    state,
    mutations,
    actions,
    getters,
};
